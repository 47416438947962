import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogPost/BlogPost.tsx";
import BlogCard from '@components/BlogCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Storybook recently introduced an on-demand architecture for 3x smaller builds and faster load times in the `}<a parentName="p" {...{
        "href": "https://storybook.js.org/blog/storybook-6-4/"
      }}>{`6.4 release`}</a>{`. I won't dive into the `}<a parentName="p" {...{
        "href": "https://storybook.js.org/blog/storybook-on-demand-architecture/"
      }}>{`data or details`}</a>{`. At a high-level, Storybook achieves this performance improvement by loading each story independently rather than packing them into a single monolithic bundle.`}</p>
    <p>{`Now that we can bundle and lazy-load stories can be independently, I was curious if we could take one more step in optimizing our static story assets. `}<span role="" aria-label="thinking and curious">{`🤔`}</span></p>
    <p>{`My friend, `}<a parentName="p" {...{
        "href": "https://twitter.com/milsyobtaf"
      }}>{`Dustin Younse`}</a>{`, recommended I look into `}<a parentName="p" {...{
        "href": "https://github.com/google/brotli"
      }}>{`brotli`}</a>{`, a generic-purpose lossless compression algorithm developed by Google, as a possible solution for further file shrinking.`}</p>
    <p>{`Dustin and his team found that using brotli compression for their design system Storybook significantly improved load speeds for teams in Tokyo and Singapore (APAC). This may not be an issue if using a low-latency CDN for your teams Storybook. However, brotli compression is an easy integration that might be worth experimenting! `}<span role="img" aria-label="test tube">{`🧪`}</span></p>
    <BlogCard mdxType="BlogCard">
  A quick note on compatibility. Not all static servers support delivering
  brotli compressed files. At the time of writing this, GitHub Pages
  automatically compresses and serves assets using gzip. Check your static
  service provider's compression compatibility or enable serving brotli if you
  are using your own server.
    </BlogCard>
    <h2>{`Adding Brotli`}</h2>
    <p>{`Adding brotli compression to our Storybook configuration is made convenient thanks to the to the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/brotli-webpack-plugin"
      }}>{`brotli-webpack-plugin`}</a>{`. First, install the plugin package as a dev dependency.`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install --save-dev brotli-webpack-plugin
`}</code></pre>
    <p>{`Then, add the plugin to the `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` webpack config for Storybook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const BrotliPlugin = require('brotli-webpack-plugin');

module.exports = {
  // Add the brotli plugin to your final webpack configuration
  webpackFinal: async (config, { configType }) => {
    if (configType === 'PRODUCTION') {
      config.plugins.push(
        new BrotliPlugin({
          asset: '[path].br[query]',
          test: /\\.(js|css|html|svg)$/,
          threshold: 10240,
          minRatio: 0.8,
        })
      );
    }
    return config;
  },
};
`}</code></pre>
    <p>{`Build a production-ready version of the Storybook. This is usually the `}<inlineCode parentName="p">{`build-storybook`}</inlineCode>{` script when using Storybook's default setup:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm run build-storybook
`}</code></pre>
    <p>{`The output will include `}<inlineCode parentName="p">{`*.(js|css|html|svg)`}</inlineCode>{` files as usual. With the plugin installed, it should now also generate `}<inlineCode parentName="p">{`*.(js|css|html|svg).br`}</inlineCode>{` assets which will be preferred and served by brotli compatible static servers.`}</p>
    <p>{`One way we can test the Storybook output is by using `}<a parentName="p" {...{
        "href": "https://github.com/http-party/http-server"
      }}><inlineCode parentName="a">{`http-server`}</inlineCode></a>{` static HTTP server with the `}<inlineCode parentName="p">{`-b`}</inlineCode>{` or `}<inlineCode parentName="p">{`--brotli`}</inlineCode>{` flag enabled. Preview it locally by running the following command:`}</p>
    <pre><code parentName="pre" {...{}}>{`npx http-server -b ./path/to/build
`}</code></pre>
    <p>{`You can verify the brotli encoding in the the Chrome DevTools `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/docs/devtools/network/"
      }}>{`Network tab`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "65.54054054054055%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/645b21ae03392be1b857c15b5e83b1f1/cbe2e/network-tab-content-encoding.webp 148w", "/static/645b21ae03392be1b857c15b5e83b1f1/3084c/network-tab-content-encoding.webp 295w", "/static/645b21ae03392be1b857c15b5e83b1f1/5ca24/network-tab-content-encoding.webp 590w", "/static/645b21ae03392be1b857c15b5e83b1f1/8369b/network-tab-content-encoding.webp 780w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/645b21ae03392be1b857c15b5e83b1f1/12f09/network-tab-content-encoding.png 148w", "/static/645b21ae03392be1b857c15b5e83b1f1/e4a3f/network-tab-content-encoding.png 295w", "/static/645b21ae03392be1b857c15b5e83b1f1/fcda8/network-tab-content-encoding.png 590w", "/static/645b21ae03392be1b857c15b5e83b1f1/a1792/network-tab-content-encoding.png 780w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/645b21ae03392be1b857c15b5e83b1f1/fcda8/network-tab-content-encoding.png",
            "alt": "Network tab to inspect brotli content encoding.",
            "title": "Network tab to inspect brotli content encoding.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      